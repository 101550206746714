import axios from "axios";

/**
 * 获取头坯（首检）列表——分页列表
 */
export const fetchFirstInspectionList = ({ pageNum, pageSize, ...paramObj }) => {
  return axios({
    url: "/dtsum/zongtong/headembryo/HeadEmbryoController/FirstInspectionHomePage",
    method: "GET",
    params: {
      pageNum,
      pageSize,
      param: paramObj
    }
  });
};

/**
 * 获取首检表详情信息，详情弹窗中展示
 * @param {number} ztfiId 首检表id
 */
export const fetchFirstInspectionDetail = ({
  ztfiId,
  machineNo: deviceNum,
  weaveDetail,
  ztgcpoiId,
        fNumber
}) => {
  return axios({
    url: "/dtsum/zongtong/headembryo/HeadEmbryoController/pieceClothInstructionSheetDisplay",
    method: "GET",
    params: {
      param: {
        ztfiId,
        deviceNum,
        weaveDetail,
        ztgcpoiId,
        fNumber
      }
    }
  });
};
