<template>
  <div id="page-first-inspection">
    <!-- 筛选区块 -->
    <div class="filter">
      <div class="filter-item">
        <Select v-model="listParam.searchType" style="width: 120px">
          <Option v-for="item in searchTypeList" :value="item.value" :key="item.value">{{
            item.label
          }}</Option>
        </Select>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.searchText"
          @keyup.enter.native="getTableListData"
          style="width: 200px"
        />
      </div>
      <div class="filter-item">
        <span style="text-align: right; margin-right: 10px">机台号</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.machineNo"
          @keyup.enter.native="getTableListData"
          style="width: 180px"
        />
      </div>
      <div class="filter-item">
        <span style="text-align: right; margin-right: 10px">状态</span>
        <Select v-model="listParam.firstInspectionStatus" @on-change="getTableListData" style="width: 120px">
          <Option v-for="item in firstInspectionStatusList" :value="item.value" :key="item.value">{{
            item.label
          }}</Option>
        </Select>
      </div>
      <div class="filter-item">
        <span style="margin-right: 10px">头坯确认时间</span>
        <DatePicker
          type="date"
          placeholder="请选择日期"
          v-model="listParam.headEmbryoConfirmDate"
          @on-change="handleDateChange"
        />
      </div>
    </div>

    <!-- 自定义的表格操作栏 -->
    <div class="tableToolBar">
      <div class="item" @click="getTableListData">
        <i class="iconfont iconicon-shuaxin"></i>
        刷新
      </div>
      <div class="item" @click="exportTableData">
        <i class="iconfont iconicon-daochu"></i>
        导出
      </div>
    </div>
    <!-- 表格 -->
    <Table :border="true" :loading="loading" :columns="tableColumns" :data="tableData">
      <template slot-scope="{ row }" slot="action">
        <span style="color: #256de6; cursor: pointer" @click="clickDetailAction(row)">详情</span>
      </template>
      <template slot-scope="{ row }" slot="greyClothSheetNo">
        <span style="color: #256de6; cursor: pointer" @click="goToGreyClothSheetDetailPage(row)">{{
          row.greyClothSheetNo
        }}</span>
      </template>
    </Table>
    <!-- 分页 -->
    <Page
      class-name="page"
      show-sizer
      show-elevator
      show-total
      :total="listDataTotal"
      :page-size="listParam.pageSize"
      :current="listParam.pageNum"
      @on-page-size-change="pageSizeChange"
      @on-change="pageNumberChange"
    />

    <!-- 打印坯布指示单的弹窗 -->
    <PrintModal :is-show-modal.sync="isShowPrintModal" :firstInspectionInfo="currentRow" />
  </div>
</template>

<script>
import { searchTypeList, firstInspectionStatusList } from "./constant";
import { fetchFirstInspectionList } from "./api";
import PrintModal from "./printModal.vue";

export default {
  components: { PrintModal },
  name: "FirstInspection",
  data() {
    return {
      searchTypeList,
      firstInspectionStatusList,

      isShowPrintModal: false, // 控制打印弹窗的显示/隐藏，打印的是A4大小、垂直方向的表格 
      currentRow: {}, // 首页表格当前选中行的信息

      // 首页表格列表的请求参数
      listParam: {
        searchType: 1, // 1:坯布指示单号,2:合同号,3:客户名称,4:款式（客户款号）
        searchText: "",
        machineNo: "", // 机台号 如 "301#"
        firstInspectionStatus: 1, // 状态（待首检、首检通过），1全部，2待首检，3首检通过
        headEmbryoConfirmDate: "", // 头坯确认时间 如 "2021-07-10 11:00"

        pageNum: 1,
        pageSize: 10,
      },

      // 首页表格配置
      loading: false,
      tableColumns: [
        {
          title: "序号",
          type: "index",
          minWidth: 80,
        },
        {
          title: "操作",
          key: "action",
          slot: "action",
          minWidth: 120,
        },
        {
          title: "状态",
          key: "firstInspectionStatus",
          minWidth: 120,
        },
        {
          title: "机台号",
          key: "machineNo",
          minWidth: 120,
        },
        {
          title: "头坯确认时间",
          key: "headEmbryoConfirmDate",
          minWidth: 180,
        },
        {
          title: "坯布指示单号",
          key: "weaveDetail",
          minWidth: 180,
        },
        {
          title: "合同号",
          key: "contractNo",
          minWidth: 180,
        },
        {
          title: "客户款号",
          key: "clientMoneyNo",
          minWidth: 180,
        },
        {
          title: "坯布货号",
          key: "greyClothGoodsNo",
          minWidth: 180,
        },
        {
          title: "工艺参数是否修改过",
          key: "isModified",
          minWidth: 180,
        },
        {
          title: "备注",
          key: "remark",
          minWidth: 180,
        },
      ],

      tableData: [],

      listDataTotal: 0, // 分页器数据总条数
    };
  },
  created() {
    this.getTableListData();
  },
  methods: {
    getTableListData() {
      this.loading = true;
      fetchFirstInspectionList(this.listParam)
        .then((res) => {
          if (res.data.success === 1) {
            const { list } = res.data.body;

            this.tableData = list.list;
            this.listDataTotal = list.total;
            this.loading = false;
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    pageSizeChange(pageSize) {
      this.listParam.pageSize = pageSize;
      this.listParam.pageNum = 1;
      this.getTableListData();
    },

    pageNumberChange(pageNum) {
      this.listParam.pageNum = pageNum;
      this.getTableListData();
    },

    handleDateChange(formatDate, DateObj) {
      this.listParam.headEmbryoConfirmDate = formatDate;
      this.getTableListData();
    },

    clickDetailAction(row) {
      this.isShowPrintModal = true;
      this.currentRow = row;
    },

    goToGreyClothSheetDetailPage({ greyClothSheetNo }) {
      this.$router.push({
        path: "/workOrderManage/clothSheetDetail",
        query: { ztgcpoiWeaveDetail: greyClothSheetNo },
      });
    },

    // 导出表格
    exportTableData() {
      const { pageNum, pageSize, ...data } = this.listParam;
      const dataStr = this.qs.stringify(data);
      window.location.href = `${this.baseUrl}/dtsum/zongtong/headembryo/HeadEmbryoController/FirstInspectionHomePageExport?${dataStr}`;
    },
  },
};
</script>

<style lang="scss" scoped>
// 筛选区块
.filter {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  .filter-item {
    display: inline-block;
    margin: 20px 40px 20px 0;
    white-space: nowrap;
  }
}

// 表格操作栏
.tableToolBar {
  display: flex;
  font-size: $--fontsize-small;
  margin-top: -20px;
  margin-bottom: 20px;
  .item {
    color: $--color-blue-standard;
    margin-right: 30px;
    cursor: pointer;
  }
}

// 表格下面的分页器部分
.page {
  margin-top: 10px;
  text-align: center;
}
</style>

<style lang="scss">
</style>
