<template>
  <!-- 头坯(首检)页面的详情弹窗，包括打印功能 -->
  <Modal
    class="first-inspection-print-modal"
    :transfer="false"
    :footer-hide="true"
    :width="1000"
    v-model="isShowPrintModal"
    @on-ok="handleOk"
    @on-cancel="handleCancel"
    @on-visible-change="modalVisibleChange"
  >
    <div class="print-modal-content">
      <!-- 打印按钮 -->
      <div class="print-btn" v-print="'#print-area'">
        <i class="icon iconfont iconicon-dayin"></i>
        打印
      </div>

      <div id="print-area" class="print-area">
        <div class="print-title-block">
          <h2 class="heading">绍兴柯桥逸轩阁纺织品有限公司</h2>
          <div class="subheading">
            <div>建档者：{{ basicInformation.createUser }}</div>
            <div><h3 style="font-family: '仿宋'">* 坯布指示单 *</h3></div>
            <div>{{ firstInspectionInfo.weaveDetail }}</div>
          </div>
        </div>
        <!-- 表1 -->
        <table class="base-info-table">
          <tr>
            <td>坯布货号</td>
            <td>{{ basicInformation.greyClothItemNo }}</td>
            <td>下单数量</td>
            <td>{{ basicInformation.orderQuantity }}</td>
            <td>计划日期</td>
            <td>{{ basicInformation.planDate }}</td>
            <td>交货日期</td>
            <td>{{ basicInformation.orderDeliveryDate }}</td>
          </tr>
          <tr>
            <td>品名</td>
            <td>{{ basicInformation.productName }}</td>
            <td>匹重</td>
            <td>{{ basicInformation.clothWeight }}</td>
            <td>成品规格</td>
            <td>{{ basicInformation.finishedProductSpecifications }}</td>
            <td>坯布颜色</td>
            <td>{{ basicInformation.clothColor }}</td>
          </tr>
          <tr>
            <td>机型</td>
            <td>{{ basicInformation.machineType }}</td>
            <td>匹数</td>
            <td>{{ basicInformation.clothNum }}</td>
            <td>台机产量</td>
            <td>{{ basicInformation.MachineOutput }}</td>
            <td>核定转数</td>
            <td>{{ basicInformation.CheckedRunSpeed }}</td>
          </tr>
          <tr>
            <td>上机转数</td>
            <td>{{ basicInformation.superiorSpeed }}</td>
            <td>开幅线</td>
            <td>{{ basicInformation.openWidthLine }}</td>
            <td>包装方式</td>
            <td>{{ basicInformation.packing }}</td>
            <td>余纱</td>
            <td>{{ basicInformation.extraYarn }}</td>
          </tr>
          <tr>
            <td>条距</td>
            <td>{{ basicInformation.sliverSpacing }}</td>
            <td>面料颜色</td>
            <td>{{ basicInformation.fabricColor }}</td>
            <td>门幅</td>
            <td>{{ basicInformation.fabricWidth }}</td>
            <td>克重</td>
            <td>{{ basicInformation.embryoBookWeight }}</td>
          </tr>
          <tr style="height: 40px">
            <td>备注</td>
            <!-- 机修工基本信息备注 -->
            <td colspan="7" class="cell-left">
              {{ basicInformation.basicInformationRemarksByM }}
            </td>
          </tr>
        </table>

        <!-- 表2 -->
        <table style="margin-top: 6px">
          <tr>
            <td colspan="3">项目名称</td>
            <td>纱线色号</td>
            <td>比率</td>
            <td>需求数</td>
          </tr>
          <tr v-for="(materialItem, index) in printedMaterialList" :key="index">
            <template v-if="index === 0">
              <td rowspan="10" style="width: 2%; padding: 0 8px 0">原料规格</td>
              <td style="width: 4%">{{ String.fromCharCode(97 + index) }}</td>
              <td style="width: 50%">{{ materialItem.specification }}</td>
              <td>{{ materialItem.yarnColorNumber }}</td>
              <td>{{ materialItem.ratio }}</td>
              <td>{{ materialItem.orderDemandNum }}</td>
            </template>
            <template v-else>
              <td>{{ String.fromCharCode(97 + index) }}</td>
              <td>{{ materialItem.specification }}</td>
              <td>{{ materialItem.yarnColorNumber }}</td>
              <td>{{ materialItem.ratio }}</td>
              <td>{{ materialItem.orderDemandNum }}</td>
            </template>
          </tr>
          <tr style="height: 40px">
            <td>备注</td>
            <td colspan="6" class="cell-left">
              {{ basicInformation.materialSpecificationRemarksM }}
            </td>
          </tr>
        </table>

        <!-- 其余的表 -->
        <div class="print-bottom-block" style=" margin-bottom: 30px">
          <div>
            <table style="height: 100%; margin-bottom: 6px">
              <tr style="height: 80px">
                <td style="width: 16%">试样详情</td>
                <td class="cell-left">{{ basicInformation.styleDetailsRemarks }}</td>
              </tr>
              <tr style="height: 80px">
                <td>注意事项</td>
                <td class="cell-left"></td>
              </tr>
            </table>
          </div>
          <div>
            <table style="height: 160px">
              <tr>
                <td>原样</td>
              </tr>
              <tr>
                <td></td>
              </tr>
            </table>
          </div>
        </div>
        <table style="height:170px; margin-bottom: 6px; margin-top: 6px;">
          <tr style="height: 0px">
            <td>备注</td>
          </tr>
          <tr style="height: 40px">
            <td class="cell-left">{{ basicInformation.directorInformationNotes }}</td>
          </tr>
        </table>
          <div class="sanjiao" style="margin-bottom: 6px">
            <span style="width: 254px">三角配置</span>
          </div>
          <table style="margin-bottom: 6px">
             <tr v-if=" triangularConfigList">
            <td style="font-size: 5px">序号</td>
            <td style="font-size: 5px">选择</td>
          <td style="font-size: 5px">1</td>
          <td style="font-size: 5px">2</td>
          <td style="font-size: 5px">3</td>
          <td style="font-size: 5px">4</td>
          <td style="font-size: 5px">5</td>
          <td style="font-size: 5px">6</td>
          <td style="font-size: 5px">7</td>
          <td style="font-size: 5px">8</td>
          <td style="font-size: 5px">9</td>
          <td style="font-size: 5px">10</td>
          <td style="font-size: 5px">11</td>
          <td style="font-size: 5px">12</td>
          <td style="font-size: 5px">13</td>
          <td style="font-size: 5px">14</td>
          <td style="font-size: 5px">15</td>
          <td style="font-size: 5px">16</td>
          <td style="font-size: 5px">17</td>
          <td style="font-size: 5px">18</td>
          <td style="font-size: 5px">19</td>
          <td style="font-size: 5px">20</td>
          <td style="font-size: 5px">21</td>
          <td style="font-size: 5px">22</td>
          <td style="font-size: 5px">23</td>
          <td style="font-size: 5px">24</td>
          <td style="font-size: 5px">25</td>
          <td style="font-size: 5px">26</td>
          <td style="font-size: 5px">27</td>
          <td style="font-size: 5px">28</td>
          <td style="font-size: 5px">29</td>
          <td style="font-size: 5px">30</td>
          <td style="font-size: 5px">31</td>
          <td style="font-size: 5px">32</td>
          <td style="font-size: 5px">33</td>
          <td style="font-size: 5px">34</td>
          <td style="font-size: 5px">35</td>
          <td style="font-size: 5px">36</td>
          
          </tr>
          <tr v-for="(item, index) in triangularConfigList" :key="index">
            <td style="width: 25.4px;font-size: 5.4px">{{item.zttcFbillFloatstandby1}}</td> 
                <td style="width: 25.4px">{{item.a0}}</td>
                <td style="width: 25.4px">{{item.a1}}</td>
                <td style="width: 25.4px">{{item.a2}}</td>
                <td style="width: 25.4px">{{item.a3}}</td>
                <td style="width: 25.4px">{{item.a4}}</td>
                <td style="width: 25.4px">{{item.a5}}</td>
                <td style="width: 25.4px">{{item.a6}}</td>
                <td style="width: 25.4px">{{item.a7}}</td>
                <td style="width: 25.4px">{{item.a8}}</td>
                <td style="width: 25.4px">{{item.a9}}</td>
                <td style="width: 25.4px">{{item.a10}}</td>
                <td style="width: 25.4px">{{item.a11}}</td>
                <td style="width: 25.4px">{{item.a12}}</td>
                <td style="width: 25.4px">{{item.a13}}</td>
                <td style="width: 25.4px">{{item.a14}}</td>
                <td style="width: 25.4px">{{item.a15}}</td>
                <td style="width: 25.4px">{{item.a16}}</td>
                <td style="width: 25.4px">{{item.a17}}</td>
                <td style="width: 25.4px">{{item.a18}}</td>
                <td style="width: 25.4px">{{item.a19}}</td>
                <td style="width: 25.4px">{{item.a20}}</td>
                <td style="width: 25.4px">{{item.a21}}</td>
                <td style="width: 25.4px">{{item.a22}}</td>
                <td style="width: 25.4px">{{item.a23}}</td>
                <td style="width: 25.4px">{{item.a24}}</td>
                <td style="width: 25.4px">{{item.a25}}</td>
                <td style="width: 25.4px">{{item.a26}}</td>
                <td style="width: 25.4px">{{item.a27}}</td>
                <td style="width: 25.4px">{{item.a28}}</td>
                <td style="width: 25.4px">{{item.a29}}</td>
                <td style="width: 25.4px">{{item.a30}}</td>
                <td style="width: 25.4px">{{item.a31}}</td>
                <td style="width: 25.4px">{{item.a32}}</td>
                <td style="width: 25.4px">{{item.a33}}</td>
                <td style="width: 25.4px">{{item.a34}}</td>
                <td style="width: 25.4px">{{item.a35}}</td>
                <td style="width: 25.4px">{{item.a36}}</td>
          </tr>
        </table>
        <div class="sanjiao" style="margin-bottom: 6px">
            <span style="width: 254px">织针排列</span>
          </div>
        <table style="margin-bottom: 6px">
          <tr v-if=" needleArrangementConfigList">
            <td style="font-size: 5px">序号</td>
            <td style="font-size: 5px">选择</td>
          <td style="font-size: 5px">1</td>
          <td style="font-size: 5px">2</td>
          <td style="font-size: 5px">3</td>
          <td style="font-size: 5px">4</td>
          <td style="font-size: 5px">5</td>
          <td style="font-size: 5px">6</td>
          <td style="font-size: 5px">7</td>
          <td style="font-size: 5px">8</td>
          <td style="font-size: 5px">9</td>
          <td style="font-size: 5px">10</td>
          <td style="font-size: 5px">11</td>
          <td style="font-size: 5px">12</td>
          <td style="font-size: 5px">13</td>
          <td style="font-size: 5px">14</td>
          <td style="font-size: 5px">15</td>
          <td style="font-size: 5px">16</td>
          <td style="font-size: 5px">17</td>
          <td style="font-size: 5px">18</td>
          <td style="font-size: 5px">19</td>
          <td style="font-size: 5px">20</td>
          <td style="font-size: 5px">21</td>
          <td style="font-size: 5px">22</td>
          <td style="font-size: 5px">23</td>
          <td style="font-size: 5px">24</td>
          <td style="font-size: 5px">25</td>
          <td style="font-size: 5px">26</td>
          <td style="font-size: 5px">27</td>
          <td style="font-size: 5px">28</td>
          <td style="font-size: 5px">29</td>
          <td style="font-size: 5px">30</td>
          <td style="font-size: 5px">31</td>
          <td style="font-size: 5px">32</td>
          <td style="font-size: 5px">33</td>
          <td style="font-size: 5px">34</td>
          <td style="font-size: 5px">35</td>
          <td style="font-size: 5px">36</td>
          <td style="font-size: 5px">37</td>
          <td style="font-size: 5px">38</td>
          <td style="font-size: 5px">39</td>
          <td style="font-size: 5px">40</td>
          <td style="font-size: 5px">41</td>
          <td style="font-size: 5px">42</td>
          <td style="font-size: 5px">43</td>
          <td style="font-size: 5px">44</td>
          <td style="font-size: 5px">45</td>
          <td style="font-size: 5px">46</td>
          <td style="font-size: 5px">47</td>
          <td style="font-size: 5px">48</td>
          <td style="font-size: 5px">49</td>
          <td style="font-size: 5px">50</td>
          <!-- <td style="font-size: 5px">51</td>
          <td style="font-size: 5px">52</td>
          <td style="font-size: 5px">53</td>
          <td style="font-size: 5px">54</td>
          <td style="font-size: 5px">55</td>
          <td style="font-size: 5px">56</td>
          <td style="font-size: 5px">57</td>
          <td style="font-size: 5px">58</td>
          <td style="font-size: 5px">59</td>
          <td style="font-size: 5px">60</td> -->
          
          </tr>
          <tr v-for="(item, index) in needleArrangementConfigList" :key="index">
                   <td style="width: 25.4px;font-size: 5.4px">{{item.ztnacFbillFloatstandby1}}</td> 
                <td style="width: 25.4px">{{item.a0}}</td>
                <td style="width: 25.4px">{{item.a1}}</td>
                <td style="width: 25.4px">{{item.a2}}</td>
                <td style="width: 25.4px">{{item.a3}}</td>
                <td style="width: 25.4px">{{item.a4}}</td>
                <td style="width: 25.4px">{{item.a5}}</td>
                <td style="width: 25.4px">{{item.a6}}</td>
                <td style="width: 25.4px">{{item.a7}}</td>
                <td style="width: 25.4px">{{item.a8}}</td>
                <td style="width: 25.4px">{{item.a9}}</td>
                <td style="width: 25.4px">{{item.a10}}</td>
                <td style="width: 25.4px">{{item.a11}}</td>
                <td style="width: 25.4px">{{item.a12}}</td>
                <td style="width: 25.4px">{{item.a13}}</td>
                <td style="width: 25.4px">{{item.a14}}</td>
                <td style="width: 25.4px">{{item.a15}}</td>
                <td style="width: 25.4px">{{item.a16}}</td>
                <td style="width: 25.4px">{{item.a17}}</td>
                <td style="width: 25.4px">{{item.a18}}</td>
                <td style="width: 25.4px">{{item.a19}}</td>
                <td style="width: 25.4px">{{item.a20}}</td>
                <td style="width: 25.4px">{{item.a21}}</td>
                <td style="width: 25.4px">{{item.a22}}</td>
                <td style="width: 25.4px">{{item.a23}}</td>
                <td style="width: 25.4px">{{item.a24}}</td>
                <td style="width: 25.4px">{{item.a25}}</td>
                <td style="width: 25.4px">{{item.a26}}</td>
                <td style="width: 25.4px">{{item.a27}}</td>
                <td style="width: 25.4px">{{item.a28}}</td>
                <td style="width: 25.4px">{{item.a29}}</td>
                <td style="width: 25.4px">{{item.a30}}</td>
                <td style="width: 25.4px">{{item.a31}}</td>
                <td style="width: 25.4px">{{item.a32}}</td>
                <td style="width: 25.4px">{{item.a33}}</td>
                <td style="width: 25.4px">{{item.a34}}</td>
                <td style="width: 25.4px">{{item.a35}}</td>
                <td style="width: 25.4px">{{item.a36}}</td>
                <td style="width: 25.4px">{{item.a37}}</td>
                <td style="width: 25.4px">{{item.a38}}</td>
                <td style="width: 25.4px">{{item.a39}}</td>
                <td style="width: 25.4px">{{item.a40}}</td>
                <td style="width: 25.4px">{{item.a41}}</td>
                <td style="width: 25.4px">{{item.a42}}</td>
                <td style="width: 25.4px">{{item.a43}}</td>
                <td style="width: 25.4px">{{item.a44}}</td>
                <td style="width: 25.4px">{{item.a45}}</td>
                <td style="width: 25.4px">{{item.a46}}</td>
                <td style="width: 25.4px">{{item.a47}}</td>
                <td style="width: 25.4px">{{item.a48}}</td>
                <td style="width: 25.4px">{{item.a49}}</td>
                <td style="width: 25.4px">{{item.a50}}</td>
                <!-- <td style="width: 25.4px">{{item.a51}}</td>
                <td style="width: 25.4px">{{item.a52}}</td>
                <td style="width: 25.4px">{{item.a53}}</td>
                <td style="width: 25.4px">{{item.a54}}</td>
                <td style="width: 25.4px">{{item.a55}}</td>
                <td style="width: 25.4px">{{item.a56}}</td>
                <td style="width: 25.4px">{{item.a57}}</td>
                <td style="width: 25.4px">{{item.a58}}</td>
                <td style="width: 25.4px">{{item.a59}}</td>
                <td style="width: 25.4px">{{item.a60}}</td> -->
          </tr>
        </table>
        <div class="confirm-row">
          <div>
            <span>此头米试算是否确认成品规格&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span style="font-weight: bold">{{ touMIConfirmResult }}</span>
          </div>
          <div style="padding-right: 20px">
            <span>试算成品是否合格&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span style="font-weight: bold">{{ directorConfirmsStatusResult }}</span>
          </div>
        </div>
        <div class="footer-area">
          <span>挡车工：</span>
          <span>机修：</span>
          <span>主任：</span>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { fetchFirstInspectionDetail } from "./api";
export default {
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
      default: false
    },
    firstInspectionInfo: {
      type: Object,
      require: true,
      default: () => {}
    }
  },
  data() {
    return {
      isShowPrintModal: false,
      basicInformation: {},
      materialList: [],
      triangularConfigList: [],
      needleArrangementConfigList: []
    };
  },
  computed: {
    printedMaterialList() {
      const resultList = []; // 固定长度10，打印的时候，固定占10行

      for (let i = 0; i < 10; i++) {
        // 判断该数组的下标位置是否有值
        if (i in this.materialList) {
          resultList.push(this.materialList[i]);
          continue;
        }

        const emptyItem = {
          ztfiId: "",
          specification: "",
          yarnColorNumber: "",
          ratio: "",
          orderDemandNum: ""
        };
        resultList.push(emptyItem);
      }

      return resultList;
    },
    touMIConfirmResult() {
      // 1否，2是
      let result = "";
      if (this.basicInformation.touMIConfirm === 2) {
        result = "是";
      } else {
        result = "否";
      }
      return result;
    },
    directorConfirmsStatusResult() {
      // 1否，2是
      let result = "";
      if (this.basicInformation.directorConfirmsStatus === 2) {
        result = "是";
      } else {
        result = "否";
      }
      return result;
    }
  },
  watch: {
    isShowModal(newVal, oldVal) {
      // 监听外部变化，为内部变量赋值
      this.isShowPrintModal = newVal;
    },
    isShowPrintModal(newVal, oldVal) {
      // 监听内部变化，通知并发送新值给外部
      newVal === true && this.getDetailInfo();
      this.$emit("update:isShowModal", newVal);
    }
  },
  methods: {
    getDetailInfo() {
      const { ztfiId, machineNo, weaveDetail, ztgcpoiId, fNumber } = this.firstInspectionInfo;

      fetchFirstInspectionDetail({
        ztfiId,
        machineNo,
        weaveDetail,
        ztgcpoiId,
        fNumber
      })
        .then(res => {
          if (res.data.success === 1) {
            console.log(res);
            const { basicInformation, materialList, triangularConfigList, needleArrangementConfigList } = res.data.body;

            this.basicInformation = basicInformation;
            this.materialList = materialList;
            this.triangularConfigList = triangularConfigList;
            this.needleArrangementConfigList = needleArrangementConfigList
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    // 确定提交，通知父组件
    handleOk() {
      this.isShowPrintModal = false;
      // todo 可能需要补充
    },

    handleCancel() {
      this.isShowPrintModal = false;
    },

    modalVisibleChange(isVisible) {
      if (!isVisible) {
        // todo  显示的时候数据初始化之类的，看看需不需要
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.print-modal-content {
  width: 100%;
}

.print-btn {
  cursor: pointer;
}

.print-area {
  text-align: center;
  color: black;

  table {
    width: 100%;
    border-collapse: collapse; // 表格的边框合并，如果相邻，则共用一个边框
    font-size: 15px;

    tr {
      height: 20px; // 表格每行的高度

      th,
      td {
        border: 1px solid black;
      }
    }
  }
}

.print-title-block {
  .heading {
    font-family: "楷体";
  }
  .subheading {
    margin: -8px 12px 0 6px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

.base-info-table {
  tr td:nth-child(odd) {
    width: 10%;
  }
}

.print-bottom-block {
  margin-top: 6px;
  width: 100%;
  height: 140px;

  display: flex;

  & > *:nth-child(1) {
    flex: 1;
    margin-right: 6px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & > *:nth-child(2) {
    width: 40%;

    table {
      height: 100%;

      tr:nth-child(1) {
        height: 20px;
      }

      tr:nth-child(2) {
        height: calc(100% - 20px);
      }
    }
  }
}
.confirm-row {
  height: 30px;
  padding-left: 10px;
  border: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
// 打印页面的页脚
.footer-area {
  width: 100%;
  text-align: left;
  margin-top: 10px;

  & > * {
    display: inline-block;
    width: 33.33%;
  }

  & > * + * {
    padding-left: 80px;
  }
}

.cell-left {
  text-align: left;
  padding-left: 10px;
  vertical-align: top;
}

.sanjiao{
  width: 940px;
  border: 1px solid black;
}
</style>
<style lang="scss">
.first-inspection-print-modal {
}
</style>
