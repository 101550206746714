// 搜索类型的 Select 下拉框列表
const searchTypeList = [
  {
    value: 1,
    label: "坯布指示单号"
  },
  {
    value: 2,
    label: "合同号"
  },
  {
    value: 3,
    label: "客户名称"
  },
  {
    value: 4,
    label: "客户款号"
  }
];

// 头坯（首检）状态的 Select 下拉框列表
const firstInspectionStatusList = [
  {
    value: 1,
    label: "全部"
  },
  {
    value: 2,
    label: "待头坯"
  },
  {
    value: 3,
    label: "头坯未通过"
  },
  {
    value: 4,
    label: "头坯通过"
  }
];

export { searchTypeList, firstInspectionStatusList };
